<template>
  <div>
    <div id="app" v-if="isIpAllowed">
      <v-app class="body">
        <router-view/>
  
        <!-- TOASTR IMPLEMENTATION IS ON STORE INDEX -->
        <div class="toastr-container">
          <v-fade-transition v-for="(item, i) in $store.state.toastr" :key="i">
            <v-alert text :type="item.type">
              {{item.message}}
            </v-alert>
          </v-fade-transition>
        </div>
      </v-app>
    </div>
    <div v-if="!isIpAllowed"></div>
  </div>
</template>

<script>
import { isIpAllowed } from "./lib/utils.js";
import axios from "axios";

export default {

  name: 'App',

  data() {
    return {
      isIpAllowed: false,
    };
  },

  async beforeMount() {
    const response = await isIpAllowed(axios);
    if (response.success) this.isIpAllowed = true;
    else this.isIpAllowed = false;

    const status2fa = window.localStorage.getItem("activated")

    if (status2fa !== 'true' && this.$router.history.current.path !== "/login") {
      window.localStorage.removeItem("activated")
      this.$router.push("/login");
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/index.sass'
</style>

<style scoped>
  .toastr-container {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>