export default {
  methods: {
    roundNumber(number, precision = 0) {
      const factor = 10**precision;
      return Math.round(number * factor) / factor;
    },
    parseSymbol(symbol) {
      const reg = new RegExp(""
        + "^(?<code>.+)"           // code is required. it can be anything (IMPORTANT: some symbols contain ".")
        + "\\.(?<domain>[A-Z])"    // domain is required. should be a single uppercase letter (check DomainEnum values)
        + "\\.(?<exchange>[^.-]+)" // exchange is required and cannot contain "." and "-"
        + "(-(?<identifier>.+))?"  // identifier is optional and can be anything
        + "$"
      );
      const match = symbol.match(reg);
      return match ? match.groups : undefined;
    }
  }
}