export function parseJWT(token) {
  return JSON.parse(decodeURIComponent(escape(atob(token.split(".")[1]))));
}

export function isValidConstraintNumber(a) {
  return typeof a === "number" && !isNaN(a) && a >= 0 && a <= 100;
}

// https://en.wikipedia.org/wiki/Largest_remainder_method
export function roundWhilePreservingSum(input) {
  const result = [];
  const expectedSum = 100;
  // Calculate the expected sum from input for other use cases:
  // const expectedSum = input.reduce((acc, item) => acc + item.value, 0);

  for (let i = 0; i < input.length; i++) {
    const {value, symbol} = input[i];
    const integerValue = Math.floor(value);

    result.push({
      symbol,
      integerValue,
      value,
      remainder: value - integerValue, // store roundoff error
    });
  }

  const integerSum = result.reduce((acc, item) => acc + item.integerValue, 0);

  // Sort the array by roundoff error
  const sortedByRemainder = result.sort((i, j) => j.remainder - i.remainder);

  let difference = expectedSum - integerSum;

  // Add 1 to those most likely to round up to the next number so that
  // the difference is nullified.
  let index = 0;
  while (difference > 0) {
    sortedByRemainder[index].integerValue += 1;
    difference--;
    index++;
  }

  return result.map(i => ({ value: i.integerValue, symbol: i.symbol }));
}

export function setWeightsToAMinimum(allocation, minimum) {
  // to avoid mutation:
  allocation = allocation.map(({ symbol, value }) => ({ symbol, value }));

  let donors = allocation.filter(i => i.value > minimum).sort((a, b) => a.value - b.value);
  let donorSum = donors.reduce((acc, i) => acc + i.value, 0);
  let shareAbleAmount = donorSum - donors.length * minimum;
  let excess = allocation.filter(i => i.value < minimum).reduce((acc, i) => acc + minimum - i.value, 0);

  // check if its possible to set the minimum
  if (shareAbleAmount < excess) {
    return false;
  }
  
  // calculate the originalWeight for each donor
  donors.forEach(i => i.originalWeight = i.value / donorSum);
   
  // collect the excess from donors proportionally
  while (excess) {
    for (const item of donors) {
      let share = Math.ceil(item.originalWeight * excess);
      if (item.value - share < minimum) {
        share = item.value - minimum;
      }
      excess -= share;
      item.value -= share;
    }
  
    donors = donors.filter(i => i.value > minimum);
  }

  return allocation.map(({ symbol, value }) => {
    if (value < minimum) {
      value = minimum;
    }
    return { value, symbol };
  });
}

export const isIpAllowed = async (axiosInstance) => {
  const response = await axiosInstance.get(`${process.env.VUE_APP_REST_API}/auth/is-ip-allowed`);
  return response.data;
};

// const allocation = [
//   { symbol: "a", value: 37 },
//   { symbol: "b", value: 10 },
//   { symbol: "c", value: 15 },
//   { symbol: "d", value: 18 },
//   { symbol: "e", value: 20 },
//   { symbol: "f", value: 0 },
//   { symbol: "g", value: 0 },
//   { symbol: "h", value: 0 },
//   { symbol: "i", value: 0 },
//   { symbol: "j", value: 0 },
//   { symbol: "k", value: 0 },
//   { symbol: "l", value: 0 },
//   { symbol: "m", value: 0 },
//   { symbol: "n", value: 0 },
// ];

// const result = setWeightsToAMinimum(allocation, 7);
// console.log(result)
