import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/createportfolio",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/register-2fa",
    name: "Register 2FA",
    component: () => import("@/views/register-2fa.vue"),
  },
  {
    path: "/recover-password",
    name: "Recover Password",
    component: () => import("@/views/recover-password.vue"),
  },
  {
    path: "/verify-2fa",
    name: "Verify 2FA",
    component: () => import("@/views/verify-2fa.vue"),
  },
  {
    path: "/createportfolio",
    name: "CreatePortfolio",
    component: () => import("@/views/create-portfolio.vue"),
  },
  {
    path: "/test-error",
    component: () => import("@/components/error-test.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
