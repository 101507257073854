import Rollbar from "rollbar";
import Vue from "vue";
import store from "../store";

const ENV = process.env.NODE_ENV;
const ROLLBAR_TOKEN = process.env.VUE_APP_ROLLBAR_TOKEN;

export const rollbar = new Rollbar({
  enabled: ENV !== "development",
  accessToken: ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: "1.0.0",
    environment: ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: "1.0.0",
        guess_uncaught_frames: true,
      },
    },
  },
  transform: (payload) => {
    let state = JSON.parse(JSON.stringify(store.state));
    payload.state = state;
  },
});
Vue.prototype.$rollbar = rollbar;

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};
