import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      dropdown: 'far fa-chevron-down',
      sort: 'far fa-arrow-up',
    },
  },
})

