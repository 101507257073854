import Vue from "vue";
import { parseJWT } from "../lib/utils.js";
import BlockedError from "../errors/blocked.js";

export default {
  namespaced: true,

  state: () => ({
    loginError: false,
    accountBlocked: false,
    loginLoading: false,
    is2faEnabled: false,
    secret2fa: null,
    error2fa: false,
    passwordUpdateDialog: false,
    updating2fa: false,
    update2faError: false,
    updatingPassword: false,
    updatePasswordError: null,
    recoverPasswordLoading: false,
    recoverPasswordError: false,
    recoverPasswordSuccessMessage: null,
    sendPasswordRecoveryMailLoading: false,
    sendPasswordRecoveryMailError: false,
    sendPasswordRecoveryMailErrorMessage: '',
    user: null,
    email: null,
  }),

  getters: {
    accessToken: () => localStorage.getItem("accessToken"),
    refreshToken: () => localStorage.getItem("refreshToken"),
  },

  mutations: {
    setAccessToken: (state, val) => localStorage.setItem("accessToken", val),
    setRefreshToken: (state, val) => localStorage.setItem("refreshToken", val),
    revokeAccessToken: () => localStorage.removeItem("accessToken"),
    revokeRefreshToken: () => localStorage.removeItem("refreshToken"),
    revoke2faStatus: () => localStorage.removeItem("activated"),
    setLoginError: (state, val) => (state.loginError = val),
    setIs2faEnabled: (state, val) => (state.is2faEnabled = val),
    setError2fa: (state, val) => (state.error2fa = val),
    setAccountBlocked: (state, val) => (state.accountBlocked = val),
    setSecret2fa: (state, val) => (state.secret2fa = val),
    setLoginLoading: (state, val) => (state.loginLoading = val),
    setPasswordUpdateDialog: (state, val) => (state.passwordUpdateDialog = val),
    setUpdating2fa: (state, val) => (state.updating2fa = val),
    setUpdate2faError: (state, val) => (state.update2faError = val),
    setUpdatingPassword: (state, val) => (state.updatingPassword = val),
    setUpdatePasswordError: (state, val) => (state.updatePasswordError = val),
    setRecoverPasswordLoading: (state, val) => (state.recoverPasswordLoading = val),
    setRecoverPasswordError: (state, val) => (state.recoverPasswordError = val),
    setRecoverPasswordSuccessMessage: (state, val) => (state.recoverPasswordSuccessMessage = val),
    setSendPasswordRecoveryMailLoading: (state, val) => (state.sendPasswordRecoveryMailLoading = val),
    setSendPasswordRecoveryMailError: (state, val) => (state.sendPasswordRecoveryMailError = val),
    setSendPasswordRecoveryMailErrorMessage: (state, val) => (state.sendPasswordRecoveryMailErrorMessage = val),
    setEmail: (state, val) => (state.email = val),
  },

  actions: {
    async getUser({ commit }) {
      try {
        const { body } = await Vue.$http.get("/auth/user");
        commit("setUser", body);
        return body;
      } catch (err) {
        console.error(err);
      }
    },

    async login({ commit }, { email, password, rememberMe }) {
      let success = false;
      commit("setLoginLoading", true);
      commit("setLoginError", false);
      commit("setAccountBlocked", false);

      try {
        const { data } = await Vue.$http.post("/auth/login", {
          email,
          password,
          rememberMe,
        });

        const { accessToken, refreshToken, secret } = data;
        const is2faEnabled = !secret ? false : true;

        if (accessToken && refreshToken) {
          commit("setAccessToken", accessToken);
          commit("setRefreshToken", refreshToken);
          commit("setIs2faEnabled", is2faEnabled);
          commit("setEmail", email);
          success = true;
        } else {
          success = false;
        }
      } catch (err) {
        if (err instanceof BlockedError) {
          commit("setAccountBlocked", true);
        }
        commit("setLoginError", true);
        success = false;
      }

      commit("setLoginLoading", false);
      return success;
    },

    async updatePassword({ commit }, { oldPassword, newPassword }) {
      let success = false;
      commit("setUpdatingPassword", true);
      commit("setUpdatePasswordError", null);

      try {
        const { data } = await Vue.$http.put("/auth/update-password", {
          oldPassword,
          newPassword,
        });

        success = true;
      } catch (err) {
        success = false;
        commit("setUpdatePasswordError", err?.response?.data?.message || "Unknown error");
      }

      commit("setUpdatingPassword", false);
      return success;
    },

    async login2fa({ commit }, { email, code }) {
      let success = false;
      commit("setLoginLoading", true);
      commit("setError2fa", false);

      try {
        const { data } = await Vue.$http.post("/auth/verify-2fa", {
          email: email,
          token: code,
        });

        const { success: responseStatus } = data;

        if (responseStatus) {
          window.localStorage.setItem("activated", true);
          success = true;
        }
      } catch (err) {
        success = false;
        commit("setError2fa", true);
        window.localStorage.setItem("activated", false);
      }

      commit("setLoginLoading", false);
      return success;
    },

    async register2fa({ commit }, { email }) {
      let success = false;
      try {
        const { data } = await Vue.$http.post("/auth/register-2fa", {
          email,
        });
        commit("setSecret2fa", data.secret);
        success = true;
      } catch (err) {
        commit("setError2fa", err?.response?.data?.message, "Unkown Error");
      }
      return success;
    },

    async recoverPassword({ commit }, { token, newPassword }) {
      let success = false;
      commit("setRecoverPasswordLoading", true);
      commit("setRecoverPasswordError", false);

      try {
        const { data } = await Vue.$http.post("/auth/recover-password", {
          token: token,
          newPassword: newPassword,
        });

        commit("setRecoverPasswordSuccessMessage", data.message);
        window.setTimeout(() => commit("setRecoverPasswordSuccessMessage", null), 3000);
        success = true;
      } catch (error) {
        success = false;        
      }

      commit("setRecoverPasswordLoading", false);
      return success;
    },

    async sendPasswordRecoveryMail({ commit }, { email }) {
      let success = false;
      commit("setSendPasswordRecoveryMailLoading", true);
      commit("setSendPasswordRecoveryMailError", false);
      commit("setSendPasswordRecoveryMailErrorMessage", '');

      try {
        const { data } = await Vue.$http.post("/auth/send-recovery-email", {
          email: email,
        });

        if (!data.success) {
          commit("setSendPasswordRecoveryMailError", true);
          commit("setSendPasswordRecoveryMailErrorMessage", data.message);
        }
        else success = true;
      } catch (error) {
        commit("setSendPasswordRecoveryMailError", true);
        commit("setSendPasswordRecoveryMailErrorMessage", error?.response?.data?.message || "Unkown Error");
        success = false;        
      }

      commit("setSendPasswordRecoveryMailLoading", false);
      return success;
    }
  },
};