import "./lib/rollbar.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mixins from "./mixins.js";
import * as plugins from '@/plugins'
import { parseJWT } from "./lib/utils.js";
import axios from "axios";
import VueAxios from 'vue-axios';
import UnauthorizedError from "./errors/unauthorized.js";
import BlockedError from "./errors/blocked.js";

const PUBLIC_ENDPOINTS = [
  "/auth/login",
  "/auth/refresh",
  "/auth/send-recovery-email",
  "/auth/recover-password",
];

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_REST_API });

console.log("ENV", process.env.NODE_ENV);

const OTP_ENDPOINTS = ["/verify-2fa", "/register-2fa", "/login"];

// TODO: Implement this as a plugin
axiosInstance.interceptors.request.use(async (config) => {
  if (!PUBLIC_ENDPOINTS.includes(config.url)) {
    let accessToken = window.localStorage.getItem("accessToken");
    let refreshToken = window.localStorage.getItem("refreshToken");
    let is2faAuthenticated = window.localStorage.getItem("activated");

    // Redirect to login if there's no token
    if (!accessToken || !refreshToken) {
      if (router.currentRoute.path !== "/login") {
        router.push("/login");
      }
      throw new UnauthorizedError;
    } else if (!is2faAuthenticated && !OTP_ENDPOINTS.includes(router.currentRoute.path)) {
      router.push("/login");
      throw new UnauthorizedError;
    }

    const accExp = (parseJWT(accessToken).exp - 5) * 1000;
    // Check if access token has expired yet
    if (accExp < Date.now()) {
      const refExp = (parseJWT(refreshToken).exp - 5) * 1000;

      // Redirect to login if both access and refresh token is expired
      if (refExp < Date.now()) {
        if (router.currentRoute.path !== "/login") {
          router.push("/login");
        }
        throw new UnauthorizedError;
      }

      try {
        const { data } = await axiosInstance.post(
          '/auth/refresh',
          { refreshToken }
        );
        ;({ accessToken, refreshToken } = data);
        window.localStorage.setItem("accessToken", accessToken);
        if (refreshToken) {
          window.localStorage.setItem("refreshToken", refreshToken);
        }
      } catch(err) {
        console.error("ERROR ON REFRESH", err);
        router.push("/login");
        throw new UnauthorizedError;
      }
    }

    config.headers.common["Authorization"] = "Bearer " + accessToken;
  } else {
    // Don't need to append Authorization header to public endpoints
    delete config.headers.common["Authorization"];
  }

  return config;
});

// TODO: Move this to plugins
axiosInstance.interceptors.response.use(async response => {
  return response;
}, async error => {
  if (error instanceof UnauthorizedError || error?.response?.status === 401) {
    if (router.currentRoute.path === "/verify-2fa" || router.currentRoute.path === "/register-2fa") {
      return false;
    }

    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");

    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }

    if (error?.response?.data?.name === "Blocked") {
      throw new BlockedError;
    } else {
      throw new UnauthorizedError;
    }
  } else {
    throw error;
  }
});

Vue.use(VueAxios, axiosInstance)
Vue.config.productionTip = false

Vue.mixin(mixins);

new Vue({
  store,
  router,
  vuetify: plugins.vuetify,
  render: h => h(App)
}).$mount('#app')
